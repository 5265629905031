import { Icons } from "assets";
import classNames from "classnames";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toastMessage } from "shared/components/common/toast";
import { checkFileIsPdf } from "shared/utils/helper";
import styles from "./style.module.scss";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  required: boolean;
  error?: any;
  formikSetFieldValue: any;
  file: any;
  progress: number;
  isUploading: boolean;
  disabled: boolean;
}
const BookUpload = (props: InputProps) => {
  const {
    label,
    error,
    required,
    formikSetFieldValue,
    file,
    progress,
    isUploading,
    disabled,
  } = props;

  const onDrop = useCallback((acceptedFiles: any) => {
    let getFile: any = acceptedFiles[0];
    if (getFile) {
      const fileSizeInBytes = getFile.size;
      const fileSizeInKilobytes = fileSizeInBytes / 1024;
      const fileSizeInMegabytes = fileSizeInKilobytes / 1024;
      if (Number(fileSizeInMegabytes) < 200) {
        if (checkFileIsPdf(getFile.type)) {
          handleUploadBook(getFile);
        } else {
          toastMessage("error", "Only pdf is supported");
        }
      } else {
        toastMessage("error", "File is too large");
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleUploadBook = (bookFile: File) => {
    formikSetFieldValue("file", bookFile);
  };
  const removeFile = () => {
    formikSetFieldValue("file", null);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      {!disabled && (
        <input
          type={"file"}
          id="file"
          {...getInputProps()}
          className="d-none"
          accept="application/pdf"
        />
      )}

      <div className="position-relative mb-3">
        <div className="position-relative">
          <label className={classNames(styles.inputLabel)}>
            {label}{" "}
            {!!required && !isUploading && (
              <label className={styles.asterik}>*</label>
            )}{" "}
            {isUploading && (
              <label className={styles.progress}>{progress}%</label>
            )}
          </label>

          <label
            className={" pointer"}
            {...getRootProps({
              onClick: (e: any) => e.stopPropagation(),
            })}
            htmlFor={"file"}
          >
            <div
              className={classNames(
                styles.bookUploadContainer,
                "flex justify-center items-center"
              )}
            >
              <div
                className={classNames(
                  "flex flex-col justify-center items-center"
                )}
              >
                <Icons.BookIcon
                  className={classNames("")}
                  style={{ width: 50, height: 50 }}
                />
                <div className={classNames(styles.bookUploadContent, "mt-3")}>
                  <span className={classNames(styles.uploadText)}>
                    {file ? file?.name : "Click to upload your book"}
                  </span>
                  <span className={classNames("ms-1", styles.dragText)}>
                    {file ? "" : "or drag and drop"}
                  </span>

                  <p className="mb-0 text-center">
                    {file ? "" : "PDF (max. 200mb)"}{" "}
                  </p>
                </div>
              </div>
              {isUploading && (
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#ced4da",
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: `${progress}%`,
                      backgroundColor: "#001e41",
                      transition: "width 0.3s ease",
                    }}
                  />
                </div>
              )}
            </div>
          </label>
        </div>
        {!!error && <div className={styles.error}>{error}</div>}
      </div>
    </>
  );
};

export default BookUpload;
