import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

const login = (params?: {}) => {
  return HTTP_CLIENT.post(Endpoint.login, params);
};

const forgotPassword = (params?: {}) => {
  return HTTP_CLIENT.post(Endpoint.forgotPassword, params);
};

const logout = () => {
  return HTTP_CLIENT.post(Endpoint.logout);
};

const changePassword = (params: {
  oldPassword: string;
  newPassword: string;
}) => {
  return HTTP_CLIENT.post(Endpoint.changePassword, params);
};

const resetPassword = (payload: { token: string; password: string }) => {
  return HTTP_CLIENT.post(
    Endpoint.resetPassword,
    {
      password: payload.password,
    },
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    }
  );
};

export { login, forgotPassword, resetPassword, logout, changePassword };
