const BaseURL = "https://greenbook-api.federallabs.org/api/";

const Endpoint = {
  login: "auth/login",
  forgotPassword: "auth/forgotPassword",
  resetPassword: "auth/resetPassword",
  logout: "auth/logout",
  changePassword: "auth/changePassword",
  updateUser: "user/update",
  getdashboardData: "views/get-dashboard-stats",
  monthlyCount: "views/get-monthly-count",
  addBook: "books/create-book",
  editBook: "books/edit-book/",
  listBooks: "books/list-books-admin",
  deleteBook: "books/",
  getBook: "books/get-book/",
};

export { BaseURL, Endpoint };
