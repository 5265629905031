import classNames from "classnames";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { LoginVS } from "shared/utils/validation";
import styles from "./style.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routeConstant";
import { Icons } from "assets";
import CustomInput from "shared/components/common/customInput";
import CustomButton from "shared/components/common/customButton";
import { setAuthReducer } from "shared/redux/reducers/authSlice";
import { login } from "shared/services/authService";
import { AxiosError } from "axios";
import { toastMessage } from "shared/components/common/toast";

const initialValues: LoginType = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: LoginVS,
    onSubmit: (values, action) => {
      handleLogin(values);
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
  } = formik;

  const handleLogin = (values: LoginType) => {
    setSubmitting(true);
    login({
      email: values.email,
      password: values.password,
    })
      .then(({ data }: { data: LoginResponse }) => {
        dispatch(
          setAuthReducer({
            isLoggedIn: true,
            user: { ...data.user },
            token: data.token,
          })
        );
      })
      .catch((err: AxiosError) => {
        let data: any = err?.response?.data;
        let { handledErrorMsg } = data;
        if (handledErrorMsg) {
          toastMessage("error", handledErrorMsg);
        } else {
          toastMessage("error", "Something went wrong.");
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <main className={classNames(styles.mainContainer)}>
      <section
        className={classNames("w-full  flex flex-col items-center  relative")}
      >
        <div
          className={classNames(
            "flex flex-col items-center w-full justify-center m-auto"
          )}
        >
          <div
            className={classNames("flex md:hidden", styles.smallLogoContainer)}
          >
            <Link
              to={routeConstant.login.path}
              className={classNames(styles.smallLogo)}
            >
              <Icons.LogoIcon />
            </Link>
          </div>
          <form
            className={classNames(styles.formContainer)}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={classNames("flex flex-col items-center gap-3")}>
              <h1 className={classNames(styles.title)}>Welcome Back!</h1>
              <p className={classNames(styles.subTitle)}>
                Please log in to access exclusive features and make the most of
                your stay.
              </p>
            </div>
            <div className={classNames("flex flex-col gap-2")}>
              <CustomInput
                label="Enter your email"
                placeholder="Enter your email"
                required
                Icon={Icons.Mail}
                type="email"
                value={values.email}
                error={touched.email && errors.email ? errors.email : ""}
                onChange={handleChange("email")}
              />
              <CustomInput
                label="Password"
                placeholder="Password"
                required
                isPassword
                Icon={Icons.Lock}
                type="password"
                value={values.password}
                error={
                  touched.password && errors.password ? errors.password : ""
                }
                onChange={handleChange("password")}
              />
              <label
                className={classNames(styles.forgotLabel)}
                onClick={() => {
                  navigate(routeConstant.forgotPassword.path);
                }}
              >
                Forgot password?
              </label>
            </div>
            <div className={classNames("flex flex-col items-center mt-3")}>
              <CustomButton
                title="Log In"
                style={{ width: "100%" }}
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </section>
      <section
        className={classNames(
          "w-full flex-col items-center justify-center relative hidden md:flex",
          styles.primary
        )}
      >
        <Link
          to={routeConstant.login.path}
          className={classNames(styles.logoContainer)}
        >
          <Icons.LogoIcon />
        </Link>
      </section>
    </main>
  );
};

export default Login;
