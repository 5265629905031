import classNames from "classnames";
import React from "react";
import Select from "react-select";

interface DropdownProps {
  selected: { value: string; label: string };
  setSelected: any;
}

const YearDropdown = ({ selected, setSelected }: DropdownProps) => {
  const currentYear = new Date().getFullYear();
  const years: any = [];

  for (let year = 2024; year <= currentYear; year++) {
    years.push({ value: `${year}`, label: `${year}` });
  }
  const handleChange = (selected: any) => {
    setSelected(selected);
  };

  if (years.length > 1) {
    return (
      <div style={{ marginLeft: "25px" }}>
        <Select
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#42AAFA",
              primary: "#001e41",
            },
          })}
          defaultValue={selected}
          onChange={handleChange}
          options={years}
        />
      </div>
    );
  } else {
    return <p style={{ marginLeft: "25px" }}>{years[0].label}</p>;
  }
};

export default YearDropdown;
