import React from "react";
import Select from "react-select";

interface DropdownProps {
  listData: any;
  selected: { value: string; label: string } | undefined;
  setSelected: any;
}

const BookDropdown = ({ selected, setSelected, listData }: DropdownProps) => {
  const handleChange = (selectedOption: any) => {
    setSelected(selectedOption);
  };
  return (
    <div style={{ marginLeft: "20px" }}>
      <Select
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#42AAFA",
            primary: "#001e41",
          },
        })}
        defaultValue={selected}
        onChange={handleChange}
        options={listData}
      />
    </div>
  );
};

export default BookDropdown;
