import classNames from "classnames";
import styles from "./style.module.scss";

interface StatsCardProps {
  title: String;
  heading: String;
  headingValue: any;
  firstDetail: String;
  firstDetailValue: any;
  secondDetail: String;
  secondDetailValue: any;
  color: any;
}

const StatsCard = ({
  title,
  heading,
  headingValue,
  firstDetail,
  firstDetailValue,
  secondDetail,
  secondDetailValue,
  color,
}: StatsCardProps) => {
  return (
    <div
      className={classNames(styles.mainContainer)}
      style={{ backgroundColor: color }}
    >
      {!!heading && (
        <>
          <label className={classNames(styles.title)}>{title}</label>
          <div className="flex mt-1">
            <label>{heading}:</label>
            <label>{headingValue}</label>
          </div>
        </>
      )}
      {!!firstDetail && (
        <>
          <div className="flex mt-1">
            <label>{firstDetail}:</label>
            <label>{firstDetailValue}</label>
          </div>
        </>
      )}
      {!!secondDetail && (
        <>
          <div className="flex mt-1">
            <label>{secondDetail}:</label>
            <label>{secondDetailValue}</label>
          </div>
        </>
      )}
    </div>
  );
};

export default StatsCard;
