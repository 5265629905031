import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "../style.module.scss";

interface navItemInterface extends SideBarItemPathType {
  isActive: boolean;
  setIsOpen: (val: boolean) => void;
}
const NavItem = (props: navItemInterface) => {
  const { title, isActive, Icon, path, setIsOpen } = props;

  return (
    <>
      <Link
        className={classNames(
          isActive ? styles.activeNavItemContainer : styles.navItemContainer,
          "w-full"
        )}
        to={path ? path : ""}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        {Icon ? (
          <img src={Icon} alt="" className={classNames(styles.icon)} />
        ) : null}

        <label className={classNames(styles.heading)} role="button">
          {title}
        </label>
      </Link>
    </>
  );
};

export default NavItem;
