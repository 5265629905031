import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

const getdashboardData = (params?: {}) => {
  return HTTP_CLIENT.get(Endpoint.getdashboardData, params);
};

const monthlyViewsCount = (year: number, bookId: string | undefined) => {
  let params = !!bookId ? `?year=${year}&bookId=${bookId}` : `?year=${year}`;
  return HTTP_CLIENT.get(Endpoint.monthlyCount + params);
};

export { getdashboardData, monthlyViewsCount };
