import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

const createBook = (
  params: FormData,
  onUploadProgress: (progress: number) => void
) => {
  return HTTP_CLIENT.post(Endpoint.addBook, params, {
    onUploadProgress: (progressEvent) => {
      if (!!progressEvent.total) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(progress);
      }
    },
  });
};

const editBook = (
  id: string,
  params: FormData,
  onUploadProgress: (progress: number) => void
) => {
  return HTTP_CLIENT.post(Endpoint.editBook + id, params, {
    onUploadProgress: (progressEvent) => {
      if (!!progressEvent.total) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(progress);
      }
    },
  });
};

const listBooks = () => {
  return HTTP_CLIENT.get(Endpoint.listBooks);
};

const deleteBook = (id: string) => {
  return HTTP_CLIENT.delete(Endpoint.deleteBook + id);
};

const getBook = (id: string | undefined) => {
  return HTTP_CLIENT.get(Endpoint.getBook + id);
};
export { createBook, listBooks, deleteBook, getBook, editBook };
