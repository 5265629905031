import { AxiosError } from "axios";
import "chart.js/auto";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { toastMessage } from "shared/components/common/toast";
import { deleteBook, listBooks } from "shared/services/bookService";
import {
  AllBooksTabs,
  AllBooksTabsEnums,
  BookTypes,
} from "shared/utils/constants";
import styles from "./style.module.scss";
import Table from "./table";
import CustomTab from "shared/components/common/customTabs";
import EditProfile from "shared/components/settingsComponents/editProfile";
import ChangePassword from "shared/components/settingsComponents/changePassword";

const AllBooks = () => {
  const [activeTab, setActiveTab] = useState<string>(AllBooksTabsEnums.pdf);
  const [booksList, setBooksList]: any = useState([]);

  const handleEdit = (item: any) => {
    console.log("Edit:", item);
  };

  const handleDelete = (item: any) => {
    deleteBook(item?._id)
      .then(() => {
        setBooksList((pre: any) => {
          return pre.filter((singleBook: any) => singleBook?._id != item?._id);
        });
        toastMessage("success", "Book deleted successfully.");
      })
      .catch((err) => {
        toastMessage("error", "Something went wrong.");
      });
  };
  const hitApis = async () => {
    listBooks()
      .then(({ data }) => {
        setBooksList(data);
      })
      .catch((err: AxiosError) => {
        toastMessage("error", "Something went wrong.");
      });
  };

  useEffect(() => {
    hitApis();
  }, []);

  return (
    <div className={classNames(styles.listContainerStyle)}>
      <div
        className={classNames(styles.mainContainer, styles.containerPadding)}
      >
        <h2 className={classNames(styles.title)}>All Books</h2>
        <CustomTab
          tabs={AllBooksTabs}
          activeTab={activeTab}
          handleActiveTab={(tab) => {
            setActiveTab(tab);
          }}
        />
        {activeTab === AllBooksTabsEnums.pdf ? (
          <Table
            data={booksList?.filter((item: any) => item.type == BookTypes.pdf)}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        ) : (
          <Table
            data={booksList?.filter(
              (item: any) => item.type == BookTypes.scorm
            )}
            onEdit={handleEdit}
            onDelete={handleDelete}
            hideActions
          />
        )}
      </div>
    </div>
  );
};

export default AllBooks;
