import { ReactComponent as Rocket } from "./rocket.svg";
import DashSettings from "./dash_settings.png";
import { ReactComponent as Logout } from "./log-out.svg";
import Dashboard from "./dash_home.png";
import Add_ICON from "./add.png";
import Book_ICON from "./book.png";
import { ReactComponent as ChevLeft } from "./chev-left.svg";
import { ReactComponent as ChevRight } from "./chev-right.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as EyeDisable } from "./eye-disable.svg";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import { ReactComponent as DropDown } from "./dropDown.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as ChevDown } from "./chev-down.svg";
import { ReactComponent as TrashOutline } from "./trash-outline.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as TickSquare } from "./tick-square.svg";
import { ReactComponent as DoubleLeftChev } from "./double-left-chev.svg";
import { ReactComponent as DoubleRightChev } from "./double-right-chev.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as CameraPlus } from "./camera-plus.svg";
import { ReactComponent as LogoIcon } from "./logo.svg";
import { ReactComponent as BookIcon } from "./book_icon.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as TrashOutline2 } from "./trash-outline-2.svg";

const Icons = {
  Rocket,
  DashSettings,
  Logout,
  Dashboard,
  ChevLeft,
  ChevRight,
  Mail,
  Lock,
  Eye,
  EyeDisable,
  Hamburger,
  DropDown,
  Phone,
  Cross,
  Trash,
  Search,
  ChevDown,
  TrashOutline,
  User,
  TickSquare,
  DoubleLeftChev,
  DoubleRightChev,
  ArrowLeft,
  ArrowRight,
  CameraPlus,
  LogoIcon,
  BookIcon,
  Add_ICON,
  Book_ICON,
  Pencil,
  TrashOutline2,
};

export { Icons };
