import { Icons } from "assets";
import classNames from "classnames";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import { UpdateProfileVS } from "shared/utils/validation";
import styles from "./style.module.scss";
import { updateUser } from "shared/services/userService";
import { AxiosError } from "axios";
import { toastMessage } from "shared/components/common/toast";
import { setAuthReducer } from "shared/redux/reducers/authSlice";

const EditProfile = () => {
  const {
    auth: { user },
  } = useSelector((state: any) => state.root);

  const dispatch = useDispatch();

  const initialValues: UpdateProfileType = {
    firstName: user?.firstname ? user?.firstname : "",
    lastName: user?.lastname ? user?.lastname : "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: UpdateProfileVS,
    onSubmit: (value, action) => {
      handleUpdate();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
  } = formik;

  const handleUpdate = () => {
    setSubmitting(true);
    updateUser({
      firstName: values.firstName,
      lastName: values.lastName,
    })
      .then(({ data }: { data: UpdateUserResponse }) => {
        dispatch(
          setAuthReducer({
            user: { ...data.user },
          })
        );
        toastMessage("success", "User updated.");
      })
      .catch((err: AxiosError) => {
        let data: any = err?.response?.data;
        let { handledErrorMsg } = data;
        if (handledErrorMsg) {
          toastMessage("error", handledErrorMsg);
        } else {
          toastMessage("error", "Something went wrong.");
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={classNames("flex flex-col items-start gap-6 w-full")}>
      <h3 className={classNames(styles.title)}>Account Information</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={classNames("flex flex-col gap-2 w-full")}
      >
        <div
          className={classNames(
            "flex flex-col sm:flex-row items-center w-full gap-2 sm:gap-5"
          )}
        >
          <CustomInput
            label="First Name"
            placeholder="Enter your first name"
            required
            type="text"
            value={values.firstName}
            error={
              touched.firstName && errors.firstName ? errors.firstName : ""
            }
            onChange={handleChange("firstName")}
            customInputContainer={classNames("w-1/2")}
          />
          <CustomInput
            label="Last Name"
            placeholder="Enter your last name"
            required
            type="text"
            value={values.lastName}
            error={touched.lastName && errors.lastName ? errors.lastName : ""}
            onChange={handleChange("lastName")}
          />
        </div>
        <div
          className={classNames(
            "flex flex-col sm:flex-row items-center w-full gap-2 sm:gap-5"
          )}
        >
          <CustomInput
            label="Email"
            placeholder="Enter your email"
            required
            Icon={Icons.Mail}
            type="email"
            readOnly
            value={user?.email || ""}
          />
          <div className={classNames("relative w-full")}></div>
        </div>

        <CustomButton
          title="Save Changes"
          containerStyle={classNames("self-end")}
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </form>
    </div>
  );
};

export default EditProfile;
