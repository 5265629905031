import "chart.js/auto";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import StatsCard from "shared/components/common/statsCard";
import YearDropdown from "shared/components/common/yearDropdown";
import {
  getdashboardData,
  monthlyViewsCount,
} from "shared/services/viewsService";
import styles from "./style.module.scss";
import { getCardsData } from "shared/utils/helper";
import BookDropdown from "shared/components/common/booksDropdown";

const Dashboard = () => {
  const [countData, setCountData]: any = useState([]);
  const [booksList, setBooksList]: any = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState({
    value: `${currentYear}`,
    label: `${currentYear}`,
  });
  const [selectedBook, setSelectedBook] = useState({
    value: "All Books",
    label: "All Books",
  });
  const [graphData, setGraphData]: any = useState(null);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 100,
        },
      },
    },
  };

  const hitApis = async () => {
    getdashboardData()
      .then(({ data }: any) => {
        let preparedData = getCardsData(data);
        setCountData(preparedData);

        setBooksList(data?.booksData);
      })
      .catch((err: any) => {
        console.log("error is: ", err);
      });
    monthlyViewsCount(
      parseInt(selectedYear.value),
      selectedBook.label == "All Books" ? undefined : selectedBook.value
    )
      .then(({ data }: any) => {
        let buildGraphData = [
          {
            label: data?.name + ` Views`,
            backgroundColor: "#001e41",
            borderColor: "white",
            data: data?.monthlyCounts?.map((elem: any) => elem?.count),
          },
        ];

        setGraphData({
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: buildGraphData,
        });
      })
      .catch((err: any) => {
        console.log("error is: ", err);
      });
  };

  useEffect(() => {
    hitApis();
  }, [selectedYear, selectedBook]);

  return (
    <div className="flex flex-col gap-6 pt-6 px-3 md:px-6 h-full">
      <div className="flex flex-wrap gap-3 ">
        {countData?.map(
          (
            item: {
              title: String;
              heading: String;
              headingValue: Number;
              firstDetail: String;
              firstDetailValue: Number;
              secondDetail: String;
              secondDetailValue: Number;
              color: String;
            },
            index: Number
          ) => {
            return (
              <StatsCard
                title={item?.title}
                heading={item?.heading}
                headingValue={item?.headingValue}
                firstDetail={item?.firstDetail}
                firstDetailValue={item?.firstDetailValue}
                secondDetail={item?.secondDetail}
                secondDetailValue={item?.secondDetailValue}
                color={item?.color}
              />
            );
          }
        )}
      </div>
      <div className={classNames(styles.mainContainer, "p-6")}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <YearDropdown selected={selectedYear} setSelected={setSelectedYear} />
          {booksList?.length > 0 && (
            <BookDropdown
              listData={[
                { value: "All Books", label: "All Books" },
                ...booksList?.map((book: any) => {
                  return {
                    value: book._id,
                    label: book.bookName,
                  };
                }),
              ]}
              selected={selectedBook}
              setSelected={setSelectedBook}
            />
          )}
        </div>
        {!!graphData && <Bar data={graphData} options={options} />}
      </div>
    </div>
  );
};

export default Dashboard;
