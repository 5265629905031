"use client";
import { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import DashboardSidebar from "../dashboardSidebar";
import styles from "./style.module.scss";
import DashboardSideCanvas from "../dashboardSideCanvas";
import DashboardHeader from "../dashboardHeader";
import { sideBarConstants } from "shared/utils/constants";

interface Props {
  children: any;
}

function DashboardWraper({ children }: Props) {
  const {
    auth: { user },
    sidebar: { isShown },
  } = useSelector((state: any) => state.root);
  const [isSideCanvas, setIsSideCanvas] = useState<boolean>(false);
  const handleOpen = () => {
    setIsSideCanvas(!isSideCanvas);
  };

  return (
    <div className={classNames(styles.topLevelContainer, "p-0 m-0 ")}>
      <DashboardSideCanvas
        isOpen={isSideCanvas}
        setIsOpen={setIsSideCanvas}
        sidebarArr={sideBarConstants}
      />
      <DashboardHeader openMobile={handleOpen} user={user} />

      <div id="mainContainer" className={classNames(styles.mainContainer)}>
        <div
          className={classNames(
            styles.sideNavContainer,
            isShown ? styles.sideNavFull : styles?.sideNavHalf
          )}
          id="sideNav"
        >
          <DashboardSidebar sidebarArr={sideBarConstants} />
        </div>
        <div
          id="container-padding"
          className={classNames(styles.child, styles.listContainerStyle)}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default DashboardWraper;
