import { routeConstant } from "shared/routes/routeConstant";
import { SUPPORTED_FORMATS } from "./enum";
import { store } from "shared/redux/store";
import { resetAuthReducer } from "shared/redux/reducers/authSlice";
import { toastMessage } from "shared/components/common/toast";

const SUPPORTED_FORMATS_Pdf = ["application/pdf"];

function checkFileIsPdf(filetype: string): boolean {
  if (SUPPORTED_FORMATS_Pdf.includes(filetype)) {
    return true;
  } else {
    return false;
  }
}

function getWindowDimensions() {
  if (typeof window !== "undefined") {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  } else {
    return {
      width: 0,
      height: 0,
    };
  }
}

function isImage(filetype: string): boolean {
  if (SUPPORTED_FORMATS.includes(filetype)) {
    return true;
  } else {
    return false;
  }
}

function findScreenTitle(pathname: string) {
  let arr = Object.values(routeConstant).filter(
    (itm) => itm?.path === pathname
  );
  if (arr?.length > 0) {
    return arr[0]?.title;
  } else {
    return "";
  }
}

const resetRedux = () => {
  const { auth }: any = store.getState().root;
  if (auth?.token) {
    store.dispatch(resetAuthReducer());
    toastMessage("error", "Session Expired");
    window.location.reload();
  }
};

const getCardsData = (data: any) => {
  let preparedData = [
    {
      title: "Books",
      heading: "Total Books",
      headingValue: data?.booksCount?.total,
      firstDetail: "PDFs",
      firstDetailValue: data?.booksCount?.pdf,
      secondDetail: "SCORMs",
      secondDetailValue: data?.booksCount?.scorm,
      color: "#42aafa",
    },
    {
      title: "Views",
      heading: "Total Views",
      headingValue: data?.viewsCount?.total,
      firstDetail: "PDFs Views",
      firstDetailValue: data?.viewsCount?.pdf,
      secondDetail: "SCORMs Views",
      secondDetailValue: data?.viewsCount?.scorm,
      color: "#ff6787",
    },
    {
      title: "Total Unique Devices",
      heading: "Total Count",
      headingValue: data?.devicesCount,
      color: "#fca047",
    },
  ];
  return preparedData;
};

export {
  getWindowDimensions,
  isImage,
  findScreenTitle,
  resetRedux,
  checkFileIsPdf,
  getCardsData,
};
