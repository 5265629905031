import { Icons } from "assets";
import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./style.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { setBreadCrumb } from "shared/redux/reducers/breadCrumbSlice";
import { findScreenTitle } from "shared/utils/helper";
import { routeConstant } from "shared/routes/routeConstant";
import { setSidebarStatus } from "shared/redux/reducers/sideBarSlice";
import BreadCrumb from "../breadCrumb";
import ProfileDropDown from "../profileDropDown";

interface Props {
  openMobile: () => void;
  user: any;
}

function DashboardHeader({ openMobile, user }: Props) {
  const {
    sidebar: { isShown },
  } = useSelector((state: any) => state.root);
  const location = useLocation();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let openSidebarMobile = 1200;

  useEffect(() => {
    if (location.pathname?.includes("editBook")) {
      dispatch(
        setBreadCrumb({
          crumbs: [
            {
              title: routeConstant.allBooks.title,
              action: () => navigate(routeConstant.allBooks.path),
            },
            {
              title: routeConstant.editBook.title,
            },
          ],
        })
      );
    } else {
      dispatch(
        setBreadCrumb({
          crumbs: [
            {
              title: findScreenTitle(location.pathname),
            },
          ],
        })
      );
    }
  }, [location.pathname]);

  return (
    <>
      <div className={classNames(styles.topLevelContainer)}>
        <div className={classNames("w-full", styles.headerContainer)}>
          <div className={classNames("flex items-center gap-4")}>
            <div
              className={classNames(
                isShown
                  ? styles.logoTopContainerFull
                  : styles.logoTopContainerHalf,
                "w-full"
              )}
            >
              {!isShown ? (
                <div
                  className={classNames(
                    "flex items-center w-full",
                    styles.logoContainer
                  )}
                >
                  <Link
                    to={routeConstant.dashboard.path}
                    className={classNames(styles.logo)}
                  >
                    <Icons.LogoIcon />
                  </Link>
                </div>
              ) : (
                <div
                  className={classNames(
                    "flex items-center w-full justify-between",
                    styles.logoContainer
                  )}
                >
                  <Link
                    to={routeConstant.dashboard.path}
                    className={classNames(styles.bigLogo)}
                  >
                    <Icons.LogoIcon />
                  </Link>
                </div>
              )}
            </div>

            <Icons.DoubleRightChev
              className={classNames(styles.leftIcon)}
              onClick={() => {
                if (openSidebarMobile < width) {
                  dispatch(setSidebarStatus({ isShown: true }));
                }
              }}
              style={
                !isShown && openSidebarMobile < width ? { display: "flex" } : {}
              }
            />
            <Icons.Hamburger
              className={classNames(styles.hamburgerIcon)}
              onClick={openMobile}
            />

            <Icons.DoubleLeftChev
              className={classNames(styles.chevIcon)}
              style={
                isShown && openSidebarMobile < width
                  ? { display: "flex" }
                  : { display: "none" }
              }
              onClick={() => {
                if (openSidebarMobile < width) {
                  dispatch(setSidebarStatus({ isShown: !isShown }));
                }
              }}
            />

            <BreadCrumb />
          </div>

          <ProfileDropDown />
        </div>
      </div>
    </>
  );
}

export default DashboardHeader;
