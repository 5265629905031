import * as yup from "yup";

const passwordRegExp = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/;

const LoginVS = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required")
    .email("Invalid Email")
    .label("email"),
  password: yup.string().required("Password is Required").label("password"),
});

const ForgotPasswordVS = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required")
    .email("Invalid Email")
    .label("email"),
});
const ResetPasswordVS = yup.object().shape({
  password: yup
    .string()
    .required("Password is Required")
    .matches(
      passwordRegExp,
      "Password must contain at least One Upper Case Character, One Lower Case Character, One Special Character and One Number"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});
const UpdatePasswordVS = yup.object().shape({
  oldPassword: yup.string().required("Current Password is Required"),
  password: yup
    .string()
    .required("New Password is Required")
    .matches(
      passwordRegExp,
      "Password must contain at least One Upper Case Character, One Lower Case Character, One Special Character and One Number"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});

const UpdateProfileVS = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name  is Required")
    .label("firstName"),
  lastName: yup.string().required("Last Name  is Required").label("lastName"),
});

const AddBookVS = yup.object().shape({
  bookTitle: yup
    .string()
    .required("Book title  is Required")
    .min(5)
    .label("Book Title"),
  // desc: yup
  //   .string()
  //   .required("Short description is Required")
  //   .label("Description"),
  file: yup
    .mixed()
    .required("Please attach PDF file")
    .typeError("Invalid file"),
});

const EditBookVS = yup.object().shape({
  bookTitle: yup
    .string()
    .min(5)
    .required("Book title  is Required")
    .label("Book Title"),
  // desc: yup
  //   .string()
  //   .required("Short description is Required")
  //   .label("Description"),
});

export {
  LoginVS,
  ResetPasswordVS,
  UpdateProfileVS,
  ForgotPasswordVS,
  UpdatePasswordVS,
  AddBookVS,
  EditBookVS,
};
