import { Icons } from "assets";
import classNames from "classnames";
import { useEffect, useState } from "react";
import NavItem from "./navItem";
import styles from "./style.module.scss";
import { useLocation } from "react-router-dom";

interface SideCanvasProps {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
  sidebarArr: SideBarItemsType[];
}

const DashboardSideCanvas = ({
  isOpen,
  setIsOpen,
  sidebarArr,
}: SideCanvasProps) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState<string>(location.pathname);

  function handleClick(e: any) {
    const elem: any = document.getElementById("sideCanvas");
    if (!elem.contains(e.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    let elem: any = document.getElementById("backDropContainer");
    elem.addEventListener("click", (event: any) => {
      handleClick(event);
    });
    setActivePath(location.pathname);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <div
      className={classNames(styles.backDropContainer, "md:hidden")}
      style={isOpen ? { visibility: "visible" } : { visibility: "hidden" }}
      id="backDropContainer"
    >
      <div
        className={classNames(
          styles.mainContainer,
          isOpen ? styles.shown : styles.hidden
        )}
        id="sideCanvas"
      >
        <div
          className={classNames(
            "flex justify-between items-center px-4 py-4",
            styles.logoContainer
          )}
        >
          <Icons.LogoIcon className={classNames(styles.myLogo)} />
          <div
            className={classNames(styles.crossContainer)}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Icons.Cross />
          </div>
        </div>
        <div>
          {sidebarArr?.map((item, inx) => {
            return (
              <div className="flex flex-col items-start gap-3" key={inx}>
                <label className={classNames(styles.navTitle)}>
                  {item?.title}
                </label>

                <div className="w-full">
                  {item?.paths?.map((s: SideBarItemPathType, s_inx: number) => {
                    const props = {
                      ...s,
                      isActive: activePath === s.path ? true : false,
                      setIsOpen,
                    };
                    return <NavItem {...props} key={s_inx} />;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardSideCanvas;
