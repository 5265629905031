import { useSelector } from "react-redux";
import styles from "./style.module.scss";
import classNames from "classnames";

const BreadCrumb = () => {
  const {
    breadcrumb: { crumbs },
  } = useSelector((state: any) => state.root);
  return (
    <>
      <div className="flex items-center">
        {crumbs.map(
          (item: { title: string; action?: () => void }, indx: number) => {
            return (
              <div key={indx} className={classNames("flex items-center")}>
                <div
                  className={
                    crumbs.length == 1
                      ? styles.breadCrumbActive
                      : indx < crumbs.length - 1
                      ? styles.breadCrumbDefault
                      : styles.breadCrumbActive
                  }
                  key={indx}
                  onClick={item?.action}
                  role="button"
                >
                  {item?.title}
                </div>
                {crumbs.length - 1 > indx ? ">>" : null}
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

export default BreadCrumb;
