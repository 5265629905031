import Login from "pages/auth/login";
import { routeConstant } from "./routeConstant";
import ResetPassword from "pages/auth/resetPassword";
import ForgotPassword from "pages/auth/forgotPassword";
import Dashboard from "pages/general/dashboard";
import Settings from "pages/general/settings";
import AddBook from "pages/general/addBook";
import AllBooks from "pages/general/allBooks";
import EditBook from "pages/general/editBook";
import PrivacyPolicy from "pages/general/privacyPolicy";

const publicRoute = [
  {
    path: routeConstant.login.path,
    title: routeConstant.login.title,
    Component: Login,
  },
  {
    path: routeConstant.forgotPassword.path,
    title: routeConstant.forgotPassword.title,
    Component: ForgotPassword,
  },
  {
    path: routeConstant.resetPassword.path,
    title: routeConstant.resetPassword.title,
    Component: ResetPassword,
  },
  {
    path: routeConstant.privacyPolicy.path,
    title: routeConstant.privacyPolicy.title,
    Component: PrivacyPolicy,
  },
];
const privateRoute = [
  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: Dashboard,
  },
  {
    path: routeConstant.addBook.path,
    title: routeConstant.addBook.title,
    Component: AddBook,
  },
  {
    path: routeConstant.allBooks.path,
    title: routeConstant.allBooks.title,
    Component: AllBooks,
  },
  {
    path: routeConstant.editBook.path,
    title: routeConstant.editBook.title,
    Component: EditBook,
  },
  {
    path: routeConstant.settings.path,
    title: routeConstant.settings.title,
    Component: Settings,
  },
];

export { publicRoute, privateRoute };
