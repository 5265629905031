import { Icons } from "assets";
import { routeConstant } from "shared/routes/routeConstant";

const sideBarConstants: SideBarItemsType[] = [
  {
    title: "Main",
    paths: [
      {
        path: routeConstant.dashboard.path,
        title: routeConstant.dashboard.title,
        Icon: Icons.Dashboard,
      },
      {
        path: routeConstant.allBooks.path,
        title: routeConstant.allBooks.title,
        Icon: Icons.Book_ICON,
      },
      {
        path: routeConstant.addBook.path,
        title: routeConstant.addBook.title,
        Icon: Icons.Add_ICON,
      },
    ],
  },
  {
    title: "Help",
    paths: [
      {
        path: routeConstant.settings.path,
        title: routeConstant.settings.title,
        Icon: Icons.DashSettings,
      },
    ],
  },
];

const SettingsTabs = ["Account", "Change Password"];
const AllBooksTabs = ["PDFs", "SCROMs"];
const SettingsTabsEnums = {
  account: "Account",
  changePassword: "Change Password",
};

const AllBooksTabsEnums = {
  scorm: "SCORMs",
  pdf: "PDFs",
};

const BookTypes = {
  pdf: "pdf",
  scorm: "scorm",
};
export {
  sideBarConstants,
  SettingsTabs,
  SettingsTabsEnums,
  BookTypes,
  AllBooksTabsEnums,
  AllBooksTabs,
};
