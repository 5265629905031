//use client
import axios from "axios";
import { BaseURL } from "./endpoints";
import { store } from "shared/redux/store";
import { resetAuth } from "shared/redux/reducers/authSlice";

export const HTTP_CLIENT = axios.create({
  baseURL: BaseURL,
});

const setupAxios = (token?: any) => {
  HTTP_CLIENT.interceptors.request.use(
    (config: any) => {
      const token = store.getState().root.auth?.token;
      if (!!token) config.headers["authorization"] = `Bearer ${token}`;
      return config;
    },
    (err) => Promise.reject(err)
  );
};

HTTP_CLIENT.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.response?.status === 401) {
      const { auth } = store.getState().root;
      if (auth?.token) {
        store.dispatch(resetAuth());
        window.location.reload();
      }
    }
    return Promise.reject(err);
  }
);

export const initialConfig = () => {
  setupAxios();
};
initialConfig();
export { setupAxios };
