import { Icons } from "assets";
import { AxiosError } from "axios";
import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import { toastMessage } from "shared/components/common/toast";
import { routeConstant } from "shared/routes/routeConstant";
import { resetPassword } from "shared/services/authService";
import { ResetPasswordVS } from "shared/utils/validation";
import styles from "./style.module.scss";

const initialValues: PasswordObj = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: ResetPasswordVS,
    onSubmit: (values, action) => {
      handleApi(values);
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
  } = formik;

  const handleApi = (values: PasswordObj) => {
    setSubmitting(true);
    resetPassword({
      password: values.password,
      token: location?.search?.split("=")[1],
    })
      .then(() => {
        toastMessage("success", "Password reset successfully!");
        navigate(routeConstant.login.path);
      })
      .catch((err: AxiosError) => {
        let data: any = err?.response?.data;
        let { handledErrorMsg } = data;
        if (handledErrorMsg) {
          toastMessage("error", handledErrorMsg);
        } else {
          if (err?.response?.status === 401) {
            toastMessage("error", "Reset password link expired.");
          } else {
            toastMessage("error", "Something went wrong.");
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  useEffect(() => {
    if (location?.search?.split("=")[0].split("?")[1] !== "token") {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <main className={classNames(styles.mainContainer)}>
      <section
        className={classNames("w-full  flex flex-col items-center  relative")}
      >
        <div
          className={classNames(
            "flex flex-col items-center w-full justify-center m-auto"
          )}
        >
          <div
            className={classNames("flex md:hidden", styles.smallLogoContainer)}
          >
            <Link
              to={routeConstant.login.path}
              className={classNames(styles.smallLogo)}
            >
              <Icons.LogoIcon />
            </Link>
          </div>
          <form
            className={classNames(styles.formContainer)}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={classNames("flex flex-col items-center gap-3")}>
              <h1 className={classNames(styles.title)}>Set Password</h1>
              <p className={classNames(styles.subTitle)}>
                Set your new password.
              </p>
            </div>
            <div className={classNames("flex flex-col gap-2")}>
              <CustomInput
                label="Password"
                placeholder="Enter your password"
                required
                isPassword
                Icon={Icons.Lock}
                type="password"
                value={values.password}
                error={
                  touched.password && errors.password ? errors.password : ""
                }
                onChange={handleChange("password")}
              />
              <CustomInput
                label="Confirm Password"
                placeholder="Enter your password"
                required
                isPassword
                Icon={Icons.Lock}
                type="password"
                value={values.confirmPassword}
                error={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : ""
                }
                onChange={handleChange("confirmPassword")}
              />
            </div>
            <div className={classNames("flex flex-col items-center mt-3")}>
              <CustomButton
                title="Set Password"
                style={{ width: "100%" }}
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </section>
      <section
        className={classNames(
          "w-full flex-col items-center justify-center relative hidden md:flex",
          styles.primary
        )}
      >
        <Link
          to={routeConstant.login.path}
          className={classNames(styles.logoContainer)}
        >
          <Icons.LogoIcon />
        </Link>
      </section>
    </main>
  );
};

export default ResetPassword;
