import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardWraper from "shared/components/common/dashboardWrapper";
import { findScreenTitle } from "shared/utils/helper";

interface LayoutProps {
  path: string;
  title: string;
  Component: any;
}

function Layout({ title, Component }: Partial<LayoutProps>) {
  const {
    auth: { isLoggedIn },
  } = useSelector((state: any) => state.root);
  const location = useLocation();
  useEffect(() => {
    let title = location.pathname?.includes("editBook")
      ? "Edit Book"
      : findScreenTitle(location.pathname);
    document.title = title + " | Admin Panel";
  }, [location.pathname]);

  return !isLoggedIn ? (
    <>
      <Component />
    </>
  ) : (
    <DashboardWraper>
      <Component />
    </DashboardWraper>
  );
}

export default Layout;
