import { AxiosError } from "axios";
import "chart.js/auto";
import { useFormik } from "formik";
import { useState } from "react";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import BookUpload from "shared/components/common/fileInput";
import { toastMessage } from "shared/components/common/toast";
import { createBook } from "shared/services/bookService";
import { AddBookVS } from "shared/utils/validation";

const AddBook = () => {
  const [progress, setProgress] = useState(0);
  const initialValues: AddBookType = {
    bookTitle: "",
    // desc: "",
    file: null,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddBookVS,
    onSubmit: (value, action) => {
      hitApi();
    },
  });

  const {
    handleChange,
    setFieldValue,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = formik;

  const hitApi = () => {
    let formBody = new FormData();
    // formBody.append("desc", values.desc);
    formBody.append("bookTitle", values.bookTitle);
    formBody.append("file", values.file);
    createBook(formBody, (uploadProgress: number) => {
      console.log("ok", uploadProgress);
      setProgress(uploadProgress);
    })
      .then((res: any) => {
        resetForm();
        toastMessage(
          "success",
          "Pdf file is being processed and book will be published in a few minutes."
        );
      })
      .catch((err: AxiosError) => {
        let data: any = err?.response?.data;
        let { handledErrorMsg } = data;
        if (handledErrorMsg) {
          toastMessage("error", handledErrorMsg);
        } else {
          toastMessage("error", "Something went wrong.");
        }
      })
      .finally(() => {
        setProgress(0);
        setSubmitting(false);
      });
  };

  return (
    <div className="flex flex-col gap-6 pt-6 px-3 md:px-6 h-full w-full lg:w-2/3">
      <CustomInput
        label="Book Title"
        placeholder="Enter book title"
        required
        type="text"
        value={values.bookTitle}
        error={touched.bookTitle && errors.bookTitle ? errors.bookTitle : ""}
        onChange={handleChange("bookTitle")}
        readOnly={isSubmitting}
      />
      {/* <CustomInput
        label="Short Description"
        placeholder="Enter short description"
        required
        type="text"
        value={values.desc}
        error={touched.desc && errors.desc ? errors.desc : ""}
        onChange={handleChange("desc")}
      /> */}
      <BookUpload
        label={isSubmitting ? "Uploading File" : "Select Book"}
        required
        error={touched.file && errors.file ? errors.file : ""}
        formikSetFieldValue={setFieldValue}
        file={values.file}
        isUploading={isSubmitting}
        progress={progress}
        disabled={isSubmitting}
      />
      <CustomButton
        title="Upload Book"
        onClick={() => {
          handleSubmit();
        }}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </div>
  );
};

export default AddBook;
