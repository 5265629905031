import classNames from "classnames";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../style.module.scss";

interface navItemInterface extends SideBarItemPathType {
  isActive: boolean;
}
const NavItem = (props: navItemInterface) => {
  const { title, isActive, Icon, path } = props;
  const {
    sidebar: { isShown },
  } = useSelector((state: any) => state.root);

  return (
    <>
      <Link
        className={classNames(
          isActive ? styles.activeNavItemContainer : styles.navItemContainer,
          "w-full"
        )}
        to={path ? path : ""}
        style={!isShown ? { paddingLeft: "0px", justifyContent: "center" } : {}}
      >
        {Icon ? (
          <img
            src={Icon}
            alt=""
            className={classNames(styles.icon)}
            style={!isShown ? { marginRight: "0px" } : {}}
          />
        ) : null}

        <label className={classNames(styles.heading)} role="button">
          {title}
        </label>
      </Link>
    </>
  );
};
export default NavItem;
