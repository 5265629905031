import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavItem from "./navItem";
import styles from "./style.module.scss";
import { useLocation } from "react-router-dom";

interface Props {
  sidebarArr: SideBarItemsType[];
}

function DashboardSidebar(props: Props) {
  const { sidebarArr } = props;
  const {
    sidebar: { isShown },
  } = useSelector((state: any) => state.root);

  const location = useLocation();

  const [activePath, setActivePath] = useState<string>(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="text-center w-full flex flex-col items-center mt-6">
        <div
          className={classNames(
            isShown ? styles.sideNavFull : styles.sideNavHalf,
            "flex flex-col gap-3"
          )}
        >
          {sidebarArr?.map((item, inx) => {
            return (
              <div className="flex flex-col items-start gap-3" key={inx}>
                {isShown ? (
                  <label className={classNames(styles.navTitle)}>
                    {item?.title}
                  </label>
                ) : null}

                <div className="w-full">
                  {item?.paths?.map((s: SideBarItemPathType, s_inx: number) => {
                    const props = {
                      ...s,
                      isActive: activePath === s.path ? true : false,
                    };
                    return <NavItem {...props} key={s_inx} />;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default DashboardSidebar;
