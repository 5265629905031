import React, { useState } from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import { BookTypes } from "shared/utils/constants";
import DeleteModal from "../deleteModal";
import { Icons } from "assets";
import CustomToolTip from "shared/components/common/customToolTip";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routeConstant";

const Table = ({ data, onEdit, onDelete, hideActions }: any) => {
  const [showModal, setShowModal] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null);

  const navigate = useNavigate();

  const handleDeleteClick = (book: any) => {
    setBookToDelete(book);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    onDelete(bookToDelete); // Call the onDelete function with the selected book
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setBookToDelete(null);
  };
  return (
    <div className={classNames(styles.tableContainer)}>
      <table className={classNames(styles.responsiveTable)}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            {!hideActions && <th>Pages</th>}
            {!hideActions && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              {!hideActions && (
                <>
                  <td>{item.type == BookTypes.pdf ? item.pages : 0}</td>

                  <td className={classNames(styles.actions)}>
                    <>
                      <div
                        className={classNames(
                          "flex items-center gap-2 justify-center"
                        )}
                      >
                        <CustomToolTip label="Edit">
                          <div
                            className={classNames(styles.actionContainer)}
                            onClick={() => {
                              navigate(
                                routeConstant.editBook.path.replace(
                                  ":id",
                                  item?._id
                                )
                              );
                            }}
                          >
                            <Icons.Pencil />
                          </div>
                        </CustomToolTip>

                        <CustomToolTip label="Delete">
                          <div
                            className={classNames(styles.actionContainer)}
                            onClick={() => {
                              handleDeleteClick(item);
                            }}
                          >
                            <Icons.TrashOutline2 />
                          </div>
                        </CustomToolTip>
                      </div>
                    </>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <DeleteModal
        show={showModal}
        onClose={handleCloseModal}
        onDelete={handleConfirmDelete}
      />
    </div>
  );
};

export default Table;
