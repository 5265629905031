const routeConstant = {
  login: {
    path: "/",
    title: "Login",
  },
  forgotPassword: {
    path: "/forgot-password",
    title: "Forgot Password",
  },
  resetPassword: {
    path: "/reset-password",
    title: "Reset Password",
  },
  otp: {
    path: "/otp",
    title: "OTP",
  },
  dashboard: {
    path: "/dashboard",
    title: "Dashboard",
  },
  addBook: {
    path: "/addBook",
    title: "Add Book",
  },
  allBooks: {
    path: "/allBooks",
    title: "All Books",
  },
  editBook: {
    path: "/editBook/:id",
    title: "Edit Book",
  },
  settings: {
    path: "/settings",
    title: "Settings",
  },
  privacyPolicy: {
    path: "/privacy-policy",
    title: "Privacy Policy",
  },
};
export { routeConstant };
