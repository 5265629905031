import { AxiosError } from "axios";
import "chart.js/auto";
import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import BookUpload from "shared/components/common/fileInput";
import { toastMessage } from "shared/components/common/toast";
import { routeConstant } from "shared/routes/routeConstant";
import { editBook, getBook } from "shared/services/bookService";
import { EditBookVS } from "shared/utils/validation";
import styles from "./style.module.scss";
const EditBook = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [book, setBook]: any = useState(null);
  const [progress, setProgress] = useState(0);
  const initialValues: AddBookType = {
    bookTitle: "",
    // desc: "",
    file: null,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: EditBookVS,
    onSubmit: (value, action) => {
      hitApi();
    },
  });

  const {
    handleChange,
    setFieldValue,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = formik;

  const hitApi = () => {
    let formBody = new FormData();

    // formBody.append("desc", values.desc);
    formBody.append("bookTitle", values.bookTitle);
    values.file && formBody.append("file", values.file);
    editBook(book?._id, formBody, (uploadProgress: number) => {
      setProgress(uploadProgress);
    })
      .then(({ data }) => {
        navigate(routeConstant.allBooks.path);
        if (values.file) {
          toastMessage(
            "success",
            "Updated Pdf file is being processed and book will be published in a few minutes."
          );
        } else {
          toastMessage("success", "Book Edited.");
        }
      })
      .catch((err: AxiosError) => {
        let data: any = err?.response?.data;
        let { handledErrorMsg } = data;
        if (handledErrorMsg) {
          toastMessage("error", handledErrorMsg);
        } else {
          toastMessage("error", "Something went wrong.");
        }
      })
      .finally(() => {
        setProgress(0);
        setSubmitting(false);
      });
  };
  const getBookData = () => {
    getBook(params?.id)
      .then(({ data }) => {
        setFieldValue("bookTitle", data?.name);
        setFieldValue("desc", data?.desc);
        setBook(data);
      })
      .catch(() => {
        toastMessage("error", "Something went wrong");
      });
  };
  useEffect(() => {
    getBookData();
  }, []);
  let width = 300;
  let height = 300;
  if (book?.width > height) {
    width = (book?.width / book?.height) * 300;
  } else {
    height = (book?.height / book?.width) * 300;
  }
  return (
    <div className="flex flex-col gap-6 pt-6 px-3 md:px-6 h-full w-full lg:w-2/3">
      {!!book && (
        <>
          <CustomInput
            label="Book Title"
            placeholder="Enter book title"
            required
            readOnly={isSubmitting}
            type="text"
            value={values.bookTitle}
            error={
              touched.bookTitle && errors.bookTitle ? errors.bookTitle : ""
            }
            onChange={handleChange("bookTitle")}
          />
          {/* <CustomInput
            label="Short Description"
            placeholder="Enter short description"
            required
            type="text"
            value={values.desc}
            error={touched.desc && errors.desc ? errors.desc : ""}
            onChange={handleChange("desc")}
          /> */}

          {book?.urls?.length > 0 && (
            <div>
              <label className={classNames(styles.inputLabel)}>
                Current File
              </label>
              <img
                src={book.urls[0]}
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                  borderRadius: "10px",
                  borderWidth: "1px",
                  borderColor: "#ced4da",
                  marginTop: "10px",
                }}
              />
            </div>
          )}
          <BookUpload
            label={
              isSubmitting && !!values.file ? "Uploading File" : "Update File"
            }
            required={false}
            error={touched.file && errors.file ? errors.file : ""}
            formikSetFieldValue={setFieldValue}
            file={values.file}
            isUploading={isSubmitting && !!values.file}
            disabled={isSubmitting}
            progress={progress}
          />
          <CustomButton
            title="Edit Book"
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </>
      )}
    </div>
  );
};

export default EditBook;
