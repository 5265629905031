import classNames from "classnames";
import React from "react";
import styles from "./style.module.scss";

const DeleteModal = ({
  show,
  onClose,
  onDelete,
}: {
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className={classNames(styles.modalBackdrop)}>
      <div className={classNames(styles.modal)}>
        <p>Are you sure you want to delete this book?</p>
        <div className={classNames(styles.modalActions)}>
          <button onClick={onDelete}>Yes</button>
          <button onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
